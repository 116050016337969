<template>
  <div>
    <div class="container login-mobile  d-flex align-items-center justify-content-center " style="height:100vh">
      <div class="">
      <img src="../../../assets/logo.png" class="login-img-top mx-auto mt-2" alt="">
      <div class="row login-container w-100  mt-3 ">
        
        <div class="col-md-6 col-12 mx-auto pl-0 ">
          <div class="row col-12">
            <h4 class="text-center w-100">ورود به پنل مدیریت</h4>
             <form @submit.prevent="login" id="login-form" class="w-100 mt-3">
                    <div class="form-group">
                      <input
                        type="text"
                        class="form-control"
                        name="email"
                        placeholder="ایمیل"
                        :disabled="formDisable"
                      />
                    </div>
                    <div class="form-group">
                      <input
                        type="password"
                        class="form-control"
                        name="password"
                        placeholder="کلمه عبور"
                        :disabled="formDisable"
                      />
                    </div>
                    <div class="form-group col-12 d-flex justify-content-center ">
                      <button
                        class="btn btn-login pr-4 pl-4 pt-2 pb-2 "
                        @click.prevent="login"
                        :disabled="formDisable"
                      >
                        ورود
                      </button>
                    </div>
                  </form>
          </div>
        </div>
        <div class="col-md-6">
          <img src="../../../assets/logo.png" class="login-img w-100" alt="">
        </div>
      </div>
    </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      formDisable: false,
    };
  },

  created() {
    this.$root.CheckAdmin() ? this.$router.push("/admin") : "";
  },
  methods: {
    login() {
      this.formDisable = true;
      let loginForm = document.getElementById("login-form");
      let loginFormData = new FormData(loginForm);
      this.$axios
        .post(this.$root.baseUrl + "/api/admin/login", loginFormData)
        .then((response) => {
          this.$root.setCookie(
            `adminAuthorization = Bearer ${response.data.data.access_token}`
          , 90);
          this.$router.push("/admin");
        })
        .catch((error) => {
          this.$root.error_notification(error)
        })
        .finally(() => {
          this.formDisable = false;
        });
    },
  },
};
</script>
<style scoped>
input{
      height: 50px;
}
.login {
  display: flex;
  
  align-items: center;
}
.login-mobile{
  height: 100vh;
}
.card-title h2{
  font-size: 22px !important;
}
.btn-login{
  background-color: #25D366;
  color: white;
}
.login-container{
  display: flex;
  align-items: center;
  /* border-top-right-radius: 40px;
   border-bottom-right-radius: 40px; */
   border-radius: 30px;
  height: 500px;
       box-shadow: 5px 9px 43px 6px #0a0a0a47;
  border: 0.5px solid rgb(206, 206, 206);
}
.login-img-top{
  display: none;
}
@media screen and (max-width:1024px){
  body{
    background-color: #fff !important;
  }
  .login-mobile{
    height: unset !important;
  }
  .login-container{
    padding-top: 25px !important;
    height: unset !important;
  }
 
}
@media screen and (max-width:768px){
  .login-title{
    padding-top: 20px;
  }
  .login-mobile{
    background-color: #fff;
  }
  .login-img-top{
    margin-top: 40px;;
  }
  .login-container{
    height: 270px;
    border-radius: 15px;
  }
  .login-img{
    display: none;
  }
  .login-img-top{
    display: block;
  }
}
</style>
